import React from 'react';
import './fontello/css/fontello.css';
import './App.css';
import "animate.css/animate.min.css";
import Grid from './components/Grid';
import Col from './components/Col';
import Row from './components/Row';
import logo from './assets/logo.svg';
import mini_logo from './assets/mini_logo.svg';
import Button from './components/Button';
import Spacer from './components/Spacer';
import InnerRow from './components/InnerRow';
import TextCol from './components/TextCol';
import Contact from './components/Contact';
import vcard from './vcard.vcf';
import ScrollAnimation from 'react-animate-on-scroll';



function App() {
  
  return (
    <Grid>   
    <Row>

      <Col size={2} collapse="xs"></Col>

  <Col className="main_col" size={4}>



        <Row>
        
        <img alt="logo" className="logo" src={logo}/>
      
        </Row>



        <Spacer height={5} />

        <Row padding={"0 10% 0 10%"}>
    <Col size={1}><a className="icon-phone-alt icon" href="tel:+494033461320" target="_blank" rel="noopener noreferrer">
      <ScrollAnimation duration={2} animateIn="fadeIn">
        <Button className="button">
        <i className="icon-phone icon"/>
      </Button> </ScrollAnimation></a></Col>
    <Col size={1}><a className="icon-mail icon" href="mailto:info@visio-stb.hamburg" target="_blank" rel="noopener noreferrer">
    <ScrollAnimation duration={3} animateIn="fadeIn">
      <Button className="button">
        <i className="icon-mail-alt icon"/>
      </Button>
      </ScrollAnimation>
      
      </a></Col>
    <Col size={1}><a className="button_link" href="https://goo.gl/maps/BconZ29FFrYk76ty7" target="_blank" rel="noopener noreferrer">
    <ScrollAnimation duration={4} animateIn="fadeIn">
      <Button className="button">
        <i className="icon-location icon"/>
      </Button>
      </ScrollAnimation>
      </a></Col>
  </Row>

{/* here's where the header ends & the content grows */}      
<ScrollAnimation duration={2} animateIn="fadeIn">
        <Row height={20}><Col className="zusatz" size={1}>Fachberater für Restrukturierung und Unternehmensplanung (DStV e.V.)</Col></Row>   
        </ScrollAnimation>
{/* Firmbrand */}
<ScrollAnimation duration={2} animateIn="fadeIn">
<InnerRow> 
          <Col size={1}><i className="icon-briefcase icon"/></Col>
          <TextCol size={6}>
            <Row>
              <Col size={1}>visio Steuerberatungsgesellschaft mbH </Col>
            </Row>
          </TextCol>
          <Col size={1}></Col>   
        </InnerRow>

        </ScrollAnimation>

        <Spacer height={5} />

        <ScrollAnimation duration={2} animateIn="fadeIn">
        <InnerRow>
          <Col size={1}><i className="icon-user icon"/></Col>
          <TextCol size={6}>
            <Row>
              <Col size={1}>Sascha Gonska - Geschäftsführer</Col>
            </Row>
            <Row>
              <Col size={1}>Diplom Betriebswirt (FH)</Col>
            </Row>
            <Row>
              <Col size={1}>Steuerberater</Col>
            </Row>
          </TextCol>
          <Col size={1}></Col>   
        </InnerRow>
        </ScrollAnimation>

        <Spacer height={5} />

        <ScrollAnimation duration={2} animateIn="fadeIn">
        <InnerRow>
          <Col size={1}><i className="icon-phone icon"/></Col>
          <TextCol size={6}>
            <Row>
              <Col size={1}><a className="button_link" href="tel:+4940 3346132-0">+49 40 3346132-0</a></Col>
            </Row>
            <Row>
              <Col size={1}>Telefon</Col>
            </Row>
          </TextCol>
          <Col size={1}></Col>   
        </InnerRow>
        </ScrollAnimation>

        <Spacer height={5} />

        <ScrollAnimation duration={2} animateIn="fadeIn">
        <InnerRow>
          <Col size={1}><i className="icon-phone opacity icon"/></Col>
          <TextCol size={6}>
            <Row>
              <Col size={1}><a className="button_link" href="tel:+49 40 3346132-0">+49 40 3346132-500</a></Col>
            </Row>
            <Row>
              <Col size={1}>Fax</Col>
            </Row>
          </TextCol>
          <Col size={1}></Col>   
        </InnerRow>
        </ScrollAnimation>

        <Spacer height={5} />

        <ScrollAnimation duration={2} animateIn="fadeIn">
        <InnerRow>
          <Col size={1}><i className="icon-mail-alt icon"/></Col>
          <TextCol size={6}>
            <Row>
              <Col size={1}><a className="button_link" href="mailto:info@visio-stb.hamburg">info@visio-stb.hamburg</a></Col>
            </Row>
            <Row>
              <Col size={1}>E-Mail</Col>
            </Row>
          </TextCol>
          <Col size={1}></Col>   
        </InnerRow>
        </ScrollAnimation>

        <Spacer height={5} />

        <ScrollAnimation duration={2} animateIn="fadeIn">
        <InnerRow>
          <Col size={1}><i className="icon-location icon"/></Col>
          <TextCol size={8}>
            <Row>
              <Col size={1}>Ehrenbergstraße 33</Col>
            </Row>
            <Row>
              <Col size={1}>22767 Hamburg</Col>
            </Row>
            <Row>
              <Col size={1}>Deutschland<br /><br /><a className="button_link" href="https://goo.gl/maps/BconZ29FFrYk76ty7">
                Auf Karte zeigen</a>
                </Col>
            </Row>
          </TextCol>
        
        </InnerRow>
        </ScrollAnimation>
        <Spacer height={5} />

        <ScrollAnimation duration={2} animateIn="fadeIn">
        <Col size={1}><a className="button_link" href={vcard} target="_blank" rel="noopener noreferrer"><Contact className="contact">
        <i className="icon-user-add icon"/>
      </Contact></a></Col>
      </ScrollAnimation>
        <Spacer height={10} />
        <InnerRow><Col className="minilogo" size={1}><img className="minilogo" width="10%" src={mini_logo} alt="bildmarke visio" /></Col></InnerRow>

  </Col>

      <Col size={2} collapse="xs"></Col>
</Row>
</Grid>
  );
}

export default App;
